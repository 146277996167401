import React from 'react'
import { Dialog, Heading, Icon, Text } from '@leadrilla/pulsar'
import styled from '@emotion/styled'

const MigrationModal = ({
  open,
  setShowMigrationModal,
}: {
  open: boolean
  setShowMigrationModal: (prev: boolean) => void
}) => {
  return (
    <Dialog open={open} onClose={() => setShowMigrationModal(false)} width="content" inset="m">
      <div className="h-[300px] w-[600px] space-y-8 px-8">
        <div className="flex items-center justify-between py-4">
          <Heading level={1}>Notice</Heading>
          <div className="cursor-pointer">
            <Icon name="close" size="l" onClick={() => setShowMigrationModal(false)} />
          </div>
        </div>
        <Text>
          Please be aware that we will be performing a system update on{' '}
          {<StyledSpan>Wednesday, Feb. 21st, 2024.</StyledSpan>}
        </Text>
        <Text>
          We are excited to announce that we will be making significant updates to our platform. Our
          focus will be on enhancing our publisher integrations and utilizing higher-quality
          vendors, which will result in a better cadence of high-quality leads and calls within the
          platform, improving your experience in real-time leads and call volume.
        </Text>
        <Text>
          To ensure that we meet your expectations and continue to provide high-quality service, we
          will be increasing the price of leads and calls. We understand that this is a significant
          change, but we believe that it's necessary to enable us to better serve you.
        </Text>
        <Text>
          We appreciate your support and understanding as we continue to work hard to improve our
          platform. If you have any issues or concerns, please don't hesitate to reach out to our
          team. Service will continue as normal after the maintenance is complete.
        </Text>
      </div>
    </Dialog>
  )
}

export default MigrationModal

const StyledSpan = styled.span`
  font-weight: bold;
`
