import { useTenantConfig } from '../../hooks/TenantConfig'

export const PoweredByLogo = ({
  src,
  alt,
  height,
  width,
  className,
}: {
  src: string
  alt: string
  height: number
  width: number
  className?: string
}) => {
  const tenantConfig = useTenantConfig()
  return !['amerilife', 'amerilife_test', 'edm', 'trking'].includes(tenantConfig.tenant) ? (
    <a className={className} href="https://salesriver.com/" target="_blank" rel="noreferrer">
      <img src={src} alt={alt} height={height} width={width} />
    </a>
  ) : null
}
